import SensorHelper from '../util/sensor-helper.js'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const DAY_MILLIS = 1000 * 60 * 60 * 24 // milliseconds of one day (24h)

const [M, A] = [
  { // MUTATIONS
    SET_DEVICES: 'SET_DEVICES',
    SET_SENSORS: 'SET_SENSORS',
    SET_LOGS: 'SET_LOGS',
    SET_WLAN_DEVICES: 'SET_WLAN_DEVICES'
  },
  { // ACTIONS
    FETCH_LOGS: 'fetchLogs',
    WATCH_WLAN_DEVICES: 'watchWlanDevices',
    WATCH_DEVICES: 'watchDevices',
    SWITCH_LIGHT: 'switchLight'
  }
]

const GRUNDIG_TV_MAC = 'BC:30:7D:AD:F9:A2'
const SAMSUNG_TV_MAC = '24:FC:E5:71:D3:5A'
const WLAN_DEVICES = {
  [SAMSUNG_TV_MAC]: { label: 'Samsung TV', icon: 'fa-tv', color: 'green accent-3' },
  [GRUNDIG_TV_MAC]: { label: 'Grundig TV', icon: 'fa-tv', color: 'green accent-3' },
  '50:EC:50:4D:B5:E5': { label: 'Mi Vacuum Robot', icon: 'fa-robot', color: 'green accent-3' },
  '64:00:6A:6A:79:D0': { label: 'Dell Mini PC', icon: 'fa-server', color: 'light-blue accent-3' },
  'D8:8C:79:27:AB:67': { label: 'Google TV Chromecast', icon: 'fab fa-chromecast', color: 'green accent-3' },
  '10:D0:7A:E3:B7:9C': { label: 'Mi Laser Projector', icon: 'fas fa-video', color: 'green accent-3' },
  'F4:5C:89:9B:58:FB': { label: 'Apple Macbook Pro', icon: 'fa-laptop', color: 'pink accent-3' },
  '98:FE:94:41:A2:84': { label: 'Apple Macbook Air', icon: 'fa-laptop', color: 'pink accent-3' },
  // '64:90:C1:58:C5:BB': { label: 'WebCamera Spy', icon: 'fa-eye', color: 'deep-purple accent-3' },
  '78:8B:2A:19:EF:97': { label: 'WebCamera 360', icon: 'fa-eye', color: 'deep-purple accent-3' },
  '24:A0:74:F1:34:16': { label: 'Apple Macbook Paul', icon: 'fa-laptop', color: 'purple accent-3' },
  'E2:84:15:24:C8:56': { label: 'Деда Саша', icon: 'fa-mobile-alt', color: 'grey-dark accent-3' },
  '5C:E9:1E:BB:50:B5': { label: 'TomTom MacBook Pro M2', icon: 'fa-laptop-code', color: 'blue accent-3' },
  'F4:64:12:54:93:1B': { label: 'Sony PlayStation 5', icon: 'fa-brands fa-playstation', color: 'deep-purple accent-3' },
  '56:42:0E:09:2F:6E': {
    owner: 'Dad',
    userIcon: '90ada3ad754e78d183dfc56a737b3f71',
    label: 'Google Pixel 5',
    icon: 'fa-mobile-alt',
    color: 'blue accent-3'
  },
  '86:37:5F:0A:AE:57': {
    owner: 'Mom',
    userIcon: '79e103e7a27c22bbae9c983a5ef6959e',
    label: 'Google Pixel 5',
    icon: 'fa-mobile-alt',
    color: 'pink accent-3'
  }
}

const ONE_DAY_MILLIS = 1000 * 60 * 60 * 24 // milliseconds of one day (24h)

export default {
  namespaced: true,
  state: {
    devices: null,
    sensors: null,
    wlanDevices: null,
    logs: {
      temperature: null,
      humidity: null
    }
  },
  getters: {
    userDevices: (state) => state.wlanDevices ? Object.values(state.wlanDevices).filter(d => !!d.userIcon) : [],

    // ============= Doors and windows =============
    isOfficeWindowOpened: (state) => SensorHelper.isOpened(state.sensors, 'office', 'window'),
    isOfficeDoorOpened: (state) => SensorHelper.isOpened(state.sensors, 'office', 'door'),
    isLivingroomDoorOpened: (state) => SensorHelper.isOpened(state.sensors, 'livingroom', 'door'),
    isLivingroomBalconyOpened: (state) => SensorHelper.isOpened(state.sensors, 'livingroom', 'balcony'),
    isKitchenWindowOpened: (state) => SensorHelper.isOpened(state.sensors, 'kitchen', 'window'),
    isBedroomBalconyOpened: (state) => SensorHelper.isOpened(state.sensors, 'bedroom', 'balcony'),
    isBedroomDoorOpened: (state) => SensorHelper.isOpened(state.sensors, 'bedroom', 'door'),
    isUtilroomWindowOpened: (state) => SensorHelper.isOpened(state.sensors, 'utilroom', 'window'),
    isUtilroomDoorOpened: (state) => SensorHelper.isOpened(state.sensors, 'utilroom', 'door'),
    isBathroomDoorOpened: (state) => SensorHelper.isOpened(state.sensors, 'bathroom', 'door'),
    isPaulroomDoorOpened: (state) => SensorHelper.isOpened(state.sensors, 'paulroom', 'door'),
    isPaulroomWindowOpened: (state) => SensorHelper.isOpened(state.sensors, 'paulroom', 'window'),
    isEntranceDoorOpened: (state) => SensorHelper.isOpened(state.sensors, 'entrance', 'door'),
    // ============= Activity / presence =============
    isEntranceActive: (state) => SensorHelper.isActive(state.sensors, 'entrance'),
    isOfficeActive: (state) => SensorHelper.isActive(state.sensors, 'office'),
    isKitchenActive: (state) => SensorHelper.isActive(state.sensors, 'kitchen'),
    isPaulroomActive: (state) => SensorHelper.isActive(state.sensors, 'paulroom'),
    isLivingroomActive: (state) => SensorHelper.isActive(state.sensors, 'livingroom'),
    isBedroomActive: (state) => SensorHelper.isActive(state.sensors, 'bedroom'),
    isUtilroomActive: (state) => SensorHelper.isActive(state.sensors, 'utilroom'),
    isRoofActive: (state) => SensorHelper.isActive(state.sensors, 'roof'),
    // ============= Water leakage =============
    // isBathroomWaterLeak: (state) => SensorHelper.isLeak(state.sensors, 'bathroom'),
    isKitchenWaterLeak: (state) => SensorHelper.isLeak(state.sensors, 'kitchen'),
    // ============= Temperature and humidity =============
    kitchenTemperature: (state) => SensorHelper.getValue(state.sensors, 'kitchen', 'temperature'),
    kitchenHumidity: (state) => SensorHelper.getValue(state.sensors, 'kitchen', 'humidity'),
    balconyTemperature: (state) => SensorHelper.getValue(state.sensors, 'balcony', 'temperature'),
    balconyHumidity: (state) => SensorHelper.getValue(state.sensors, 'balcony', 'humidity'),
    livingroomTemperature: (state) => SensorHelper.getValue(state.sensors, 'livingroom', 'temperature'),
    livingroomHumidity: (state) => SensorHelper.getValue(state.sensors, 'livingroom', 'humidity'),
    bedroomTemperature: (state) => SensorHelper.getValue(state.sensors, 'bedroom', 'temperature'),
    bedroomHumidity: (state) => SensorHelper.getValue(state.sensors, 'bedroom', 'humidity'),
    officeTemperature: (state) => SensorHelper.getValue(state.sensors, 'office', 'temperature'),
    officeHumidity: (state) => SensorHelper.getValue(state.sensors, 'office', 'humidity'),
    paulroomTemperature: (state) => SensorHelper.getValue(state.sensors, 'paulroom', 'temperature'),
    paulroomHumidity: (state) => SensorHelper.getValue(state.sensors, 'paulroom', 'humidity'),
    hallTemperature: (state) => SensorHelper.getValue(state.sensors, 'hall', 'temperature'),
    hallHumidity: (state) => SensorHelper.getValue(state.sensors, 'hall', 'humidity'),
    bathroomTemperature: (state) => SensorHelper.getValue(state.sensors, 'bathroom', 'temperature'),
    bathroomHumidity: (state) => SensorHelper.getValue(state.sensors, 'bathroom', 'humidity'),
    utilroomTemperature: (state) => SensorHelper.getValue(state.sensors, 'utilroom', 'temperature'),
    utilroomHumidity: (state) => SensorHelper.getValue(state.sensors, 'utilroom', 'humidity'),
    // ============= Light =============
    isHallLightOff: SensorHelper.isLightOff('hall_vitrine'),
    isMiWirelessCleanerOff: SensorHelper.isLightOff('mi_wireless_cleaner'),
    isBalconyLightOff: SensorHelper.isLightOff('balcony_light'),
    isAquariumOff: SensorHelper.isLightOff('aquarium'),
    // ============= IP Devices =============
    // isTvOnline: SensorHelper.isDeviceOnline(GRUNDIG_TV_MAC),

    // ============= Doors and windows =============
    isOfficeWindowOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'office', 'window'),
    isOfficeDoorOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'office', 'door'),
    isLivingroomDoorOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'livingroom', 'door'),
    isLivingroomBalconyOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'livingroom', 'balcony'),
    isKitchenWindowOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'kitchen', 'window'),
    isBedroomBalconyOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'bedroom', 'balcony'),
    isBedroomDoorOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'bedroom', 'door'),
    isUtilroomWindowOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'utilroom', 'window'),
    isUtilroomDoorOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'utilroom', 'door'),
    isBathroomDoorOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'bathroom', 'door'),
    isPaulroomDoorOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'paulroom', 'door'),
    isPaulroomWindowOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'paulroom', 'window'),
    isEntranceDoorOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'entrance', 'door'),
    // ============= Activity / presence =============
    isEntranceActiveOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'entrance', 'presence', SensorHelper.DAY_MILLIS),
    isOfficeActiveOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'office', 'presence', SensorHelper.DAY_MILLIS),
    isKitchenActiveOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'kitchen', 'presence', SensorHelper.DAY_MILLIS),
    isPaulroomActiveOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'paulroom', 'presence', SensorHelper.DAY_MILLIS),
    isLivingroomActiveOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'livingroom', 'presence', SensorHelper.DAY_MILLIS),
    isBedroomActiveOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'bedroom', 'presence', SensorHelper.DAY_MILLIS),
    isUtilroomActiveOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'utilroom', 'presence', SensorHelper.DAY_MILLIS),
    isRoofActiveOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'roof', 'presence'),
    // ============= Temperature and humidity =============
    isKitchenTemperatureOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'kitchen', 'temperature'),
    isKitchenHumidityOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'kitchen', 'humidity'),
    isBalconyTemperatureOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'balcony', 'temperature'),
    isBalconyHumidityOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'balcony', 'humidity'),
    isLivingroomTemperatureOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'livingroom', 'temperature'),
    isLivingroomHumidityOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'livingroom', 'humidity'),
    isBedroomTemperatureOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'bedroom', 'temperature'),
    isBedroomHumidityOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'bedroom', 'humidity'),
    isOfficeTemperatureOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'office', 'temperature'),
    isOfficeHumidityOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'office', 'humidity'),
    isPaulroomTemperatureOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'paulroom', 'temperature'),
    isPaulroomHumidityOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'paulroom', 'humidity'),
    isHallTemperatureOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'hall', 'temperature'),
    isHallHumidityOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'hall', 'humidity'),
    isBathroomTemperatureOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'bathroom', 'temperature'),
    isBathroomHumidityOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'bathroom', 'humidity'),
    isUtilroomTemperatureOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'utilroom', 'temperature'),
    isUtilroomHumidityOutdated: (state) => SensorHelper.isOutdated(state.sensors, 'utilroom', 'humidity')
  },
  mutations: {
    [M.SET_DEVICES]: (state, devices) => { state.devices = devices },
    [M.SET_SENSORS]: (state, sensors) => { state.sensors = sensors },
    [M.SET_WLAN_DEVICES]: (state, wlanDevices) => {
      state.wlanDevices = wlanDevices
        .map(wlanDevice => ({
          ...wlanDevice,
          ...(WLAN_DEVICES[wlanDevice.mac] || {})
        }))
        .sort((a, b) => {
          const today = new Date().toISOString().substring(0, 10)
          const aDeviceActive = new Date(a.lastupdated).toISOString().substring(0, 10) === today
          const bDeviceActive = new Date(b.lastupdated).toISOString().substring(0, 10) === today
          if (aDeviceActive === bDeviceActive) {
            if ((a.type || 'wlan') === (b.type || 'wlan')) {
              return (b.lastupdated - a.lastupdated) || (((a.label || a.name) > (b.label || b.name)) ? 1 : -1)
            } else {
              if (b.type === 'ethernet') return 1
              if (a.type === 'ethernet') return -1
              return 0
            }
          } else {
            return aDeviceActive ? -1 : 1
          }
        })
    },
    [M.SET_LOGS]: (state, { item, logs }) => { state.logs[item] = logs || {} }
  },
  actions: {
    [A.FETCH_LOGS]: async ({ commit, state }, item) => {
      // ensure that sensors data is loaded
      await (new Promise(resolve => {
        (function sensorsReady (storeState) {
          if (storeState && storeState.sensors && storeState.sensors.length) {
            resolve()
          } else {
            setTimeout(sensorsReady.bind(this, storeState), 100)
          }
        })(state)
      }))

      const logSensors = state.sensors.filter(sensor => sensor.item === item)
      let logs

      if (logSensors.length) {
        const db = firebase.database()
        const firebaseSensorsLogData = await Promise.all( // parallel request for sensor data
          logSensors.map(logSensor => db.ref(`/logs/sensors/${logSensor.id}`)
            .orderByChild('lastupdated').startAt(Date.now() - ONE_DAY_MILLIS) // last 24h
            .once('value'))
        )

        logs = logSensors.reduce((logsByPlace, logSensor, idx) => {
          const sensorLogs = firebaseSensorsLogData[idx].val()
          if (sensorLogs) {
            // in case if several sensor of the same type are in the same place
            const placeKey = logsByPlace[logSensor.place] ? `${logSensor.place}-${logSensor.id}` : logSensor.place
            logsByPlace[placeKey] = Object.values(sensorLogs)
          }
          return logsByPlace
        }, {})
      }

      commit(M.SET_LOGS, { item, logs })
    },
    [A.WATCH_DEVICES]: ({ commit, state }) => {
      if (state.devices) return // already watching

      commit(M.SET_DEVICES, []) // mark as "watching"

      const db = firebase.database()
      db.ref('/devices').on('value', devicesSnapshot => {
        const devices = devicesSnapshot.val()
        commit(M.SET_DEVICES, Object.values(devices))
        commit(M.SET_SENSORS, Object.values(devices).reduce(
          (sensors, device) => {
            if (device.sensors) {
              for (const [id, sensor] of Object.entries(device.sensors)) {
                sensor.id = id
                sensor.place = device.place
                sensors.push(sensor)
              }
            }
            return sensors
          },
          [/* initial sensors */]
        ))
      })
    },
    [A.SWITCH_LIGHT]: ({ state, rootState }, { place }) => {
      const lightDevice = state.devices.filter(device =>
        device.lights &&
        device.place === place &&
        Object.values(device.lights)[0]
      )[0]
      const lightId = Object.keys(lightDevice.lights)[0]
      const light = lightDevice.lights[lightId]

      const db = firebase.database()
      db.ref('/messages').push({
        type: 'light_on',
        target: 'server',
        timestamp: Date.now(),
        user: rootState.auth.user.uid,
        lightId,
        value: !light.state.on // switch state
      })
    },
    [A.WATCH_WLAN_DEVICES]: ({ commit, state }) => {
      if (state.wlanDevices) return // already watching

      commit(M.SET_WLAN_DEVICES, []) // mark as "watching"

      const db = firebase.database()
      db.ref('/wlan/activeDevices').on('value', wlanDevicesSnapshot => {
        const wlanDevices = Object.values(wlanDevicesSnapshot.val())
        const wlanDevicesLastMonth = wlanDevices.filter(d => d.lastupdated > Date.now() - 30 * DAY_MILLIS)
        commit(M.SET_WLAN_DEVICES, wlanDevicesLastMonth)
      })
    }
  }
}
